import { useEffect } from 'react';

type HandlerID = 'documentClick';
type DocumentKey = `${HandlerID}TabbyHandler`;

declare global {
  interface Window {
    tabbyHandlers?: Partial<Record<DocumentKey, boolean>>;
  }
}

// if you need use hook useDocumentClick you have to add new id
export const useDocumentClick = (
  id: HandlerID,
  callback: (e: MouseEvent) => void
) => {
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!(e.target instanceof HTMLElement)) return;

      callback(e);
    };

    if (!window.tabbyHandlers) {
      window.tabbyHandlers = {};
    }

    if (!window.tabbyHandlers?.[`${id}TabbyHandler`]) {
      window.tabbyHandlers[`${id}TabbyHandler`] = true;
      document.addEventListener('click', handler);
    }

    return () => {
      if (window.tabbyHandlers?.[`${id}TabbyHandler`]) {
        window.tabbyHandlers[`${id}TabbyHandler`] = false;
        document.removeEventListener('click', handler);
      }
    };
  }, [callback, id]);
};
