import { datadogRum } from '@datadog/browser-rum';
import React, { useEffect } from 'react';
import { lazily } from 'react-lazily';
import { ConditionSuspense } from 'src/components/condition-suspense';
import { Portal } from 'src/components/portal';
import { ACTIONS, WIDGET_INITIALIZED } from 'src/constants/common';
import {
  AnalyticsProvider,
  AnalyticsProviderProps,
  useAnalyticsContext,
} from 'src/context/analytics-context';
import {
  AppProvider,
  MerchantProps,
  useAppContext,
} from 'src/context/store/app-provider';
import { useOnExperimentsReady } from 'src/hooks/use-on-experiments-ready';
import { usePromoSetup } from 'src/hooks/use-promo-setup';
import { initializeDatadogRUMWidgets } from 'src/utils/datadog';
import { delayDecorator } from 'src/utils/delay-decorator';
import { renderToByDefaultDecorator } from 'src/utils/render-to-by-default-decorator';

const { DefaultPromoWidget } = lazily(
  () => import('src/components/default-promo-widget')
);

const { PopupLearnMoreWithSchedule } = lazily(
  () => import('src/components/popup-learn-more-with-schedule')
);

const { PopupNonStandartLearnMore } = lazily(
  () => import('src/components/popup-non-standart-learn-more')
);

const { PopupLearnMorePayInFull } = lazily(
  () => import('src/components/popup-learn-more-pay-in-full')
);

interface Props {
  selector?: string;
}

export function PromoWidget({ selector }: Props) {
  const { price, getExperiment, dispatch } = useAppContext();
  const { onSegmentLog } = useAnalyticsContext();
  const payNowExperiment = getExperiment('CT-2431-pay-now') || 'control';

  const {
    product,
    conditionInstallmentsPopup,
    isShowInstallmentsPopup,
    hideInstallmentsPopup,
    suspenseOffInstallmentsPopup,
    conditionNonStandardInstallmentsPopup,
    isShowNonStandardInstallmentsPopup,
    hideNonStandardInstallmentsPopup,
    suspenseOffNonStandardInstallmentsPopup,
  } = usePromoSetup(false, onSegmentLog, 'tabbyInfo');

  const shouldShowSchedule =
    conditionInstallmentsPopup && payNowExperiment === 'control';
  const shouldShowPayInFull =
    conditionInstallmentsPopup && payNowExperiment !== 'control';

  useEffect(() => {
    if (shouldShowPayInFull) {
      dispatch({
        type: ACTIONS.PREPARE_PAY_IN_FULL_POPUP,
      });
    }
  }, [shouldShowPayInFull, dispatch]);

  useEffect(() => {
    if (shouldShowSchedule) {
      dispatch({
        type: ACTIONS.PREPARE_SCHEDULE_POPUP,
      });
    }
  }, [shouldShowSchedule, dispatch]);

  useOnExperimentsReady(initializeDatadogRUMWidgets);

  return price || payNowExperiment !== 'control' ? (
    <div>
      <Portal to={selector}>
        <ConditionSuspense condition={product === 'default'}>
          <DefaultPromoWidget />
        </ConditionSuspense>
      </Portal>

      <Portal to="body">
        <ConditionSuspense condition={shouldShowSchedule}>
          <PopupLearnMoreWithSchedule
            isShow={isShowInstallmentsPopup}
            onClose={() => hideInstallmentsPopup('cross')}
            onInactiveZoneClick={() => hideInstallmentsPopup('inactive zone')}
            onContinue={() => hideInstallmentsPopup('continue')}
            onCloseAfter={suspenseOffInstallmentsPopup}
          />
        </ConditionSuspense>

        <ConditionSuspense condition={shouldShowPayInFull}>
          <PopupLearnMorePayInFull
            isShow={isShowInstallmentsPopup}
            onClose={() => hideInstallmentsPopup('cross')}
            onContinue={() => hideInstallmentsPopup('continue')}
            onInactiveZoneClick={() => hideInstallmentsPopup('inactive zone')}
            onCloseAfter={suspenseOffInstallmentsPopup}
          />
        </ConditionSuspense>
      </Portal>

      <Portal to="body">
        <ConditionSuspense condition={conditionNonStandardInstallmentsPopup}>
          <PopupNonStandartLearnMore
            isShow={isShowNonStandardInstallmentsPopup}
            onClose={hideNonStandardInstallmentsPopup}
            onCloseAfter={suspenseOffNonStandardInstallmentsPopup}
          />
        </ConditionSuspense>
      </Portal>
    </div>
  ) : null;
}

declare global {
  interface Window {
    TabbyPromo: (
      props?: Props & MerchantProps & Omit<AnalyticsProviderProps, 'children'>
    ) => void;
  }
}

window.TabbyPromo = (props) => {
  datadogRum.addAction(WIDGET_INITIALIZED, {
    constructorName: 'TabbyPromo',
    ...props,
  });

  delayDecorator(() => {
    renderToByDefaultDecorator(
      props?.selector || '',
      <AppProvider {...props}>
        <AnalyticsProvider props={props}>
          <PromoWidget selector={props?.selector} />
        </AnalyticsProvider>
      </AppProvider>
    );
  });
};
