export const INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
];

export const INSTALLMENT_PLANS = {
  four: 4,
  six: 6,
  eight: 8,
  twelve: 12,
};

export const INITIAL_PRICE = 100;
